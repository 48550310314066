<template>
  <div>
    <b-card>
      <b-form @submit.prevent>
        <b-row>
          <b-col cols="12">
            <b-form-group
                label="Kod"
                label-for="code"
            >
              <b-form-input
                  id="code"
                  placeholder="Kod"
                  v-model="code"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group
                label="Ad"
                label-for="name"
            >
              <b-form-input
                  id="name"
                  placeholder="Ad"
                  v-model="name"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group
                label="Renk"
                label-for="detail"
            >
              <b-form-input
                  id="detail"
                  placeholder="Renk"
                  v-model="description"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group
                label="Adet"
                label-for="quantity"
            >
              <b-form-input
                  id="quantity"
                  type="number"
                  placeholder="1"
                  v-model="quantity"
              />
            </b-form-group>
          </b-col>

          <!-- submit and reset -->
          <b-col cols="12">
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1"
                @click.once="productSave"
            >
              Kaydet
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BTable,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BBadge,
  BForm,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from "axios";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BBadge,
    BForm,
  },
  directives: {
    Ripple,
  },
  props: {
    productInfo: Object,
  },
  data() {
    return {
      productId: 0,
      code: '',
      name: '',
      description: '',
      quantity: '',
    }
  },
  computed: {},
  mounted() {

  },
  created() {
    this.productId = this.productInfo.id;
    this.code = this.productInfo.code;
    this.name = this.productInfo.name;
    this.description = this.productInfo.description;
    this.quantity = this.productInfo.quantity;
  },
  methods: {
    productSave() {
      axios.put('/product/update', {
        productId: this.productId,
        code: this.code,
        name: this.name,
        description: this.description,
        quantity: this.quantity
      }).then(response => {
        this.$toastApiResponse(response.data)
        this.$emit('editModal', true)
      })
          .catch(err => {
            this.$toastBaha(err.message, 'danger')
          })
    }
  },
}
</script>

<style>

</style>
